/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { AgencyForm } from 'components/Shared/SharedForms/AgencyForm';
import { ResourceForm } from 'components/Shared/SharedForms/ResourceForm';
import { SiteForm } from 'components/Shared/SharedForms/SiteForm';
import { TaxonomyForm } from 'components/Shared/SharedForms/TaxonomyForm';
import { VerificationsForm } from 'components/Shared/SharedForms/VerificationsForm';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { palette } from 'design/theme/palette';
import { selectUser } from 'global-state/selectors';
import { validateRequired } from 'helpers/validation.helpers';
import { useAgencies } from 'hooks/useAgencies';
import { useSites } from 'hooks/useSites';
import { useTaxonomies } from 'hooks/useTaxonomies';
import { useVerifications } from 'hooks/useVerifications';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Agency } from 'types/entities/agencies';
import { Resource } from 'types/entities/resources';
import { Site } from 'types/entities/sites';
import { Taxonomy } from 'types/entities/taxonomies';
import { Verification } from 'types/entities/verifications';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftTab = styled(Tab)`
  padding: 0 0 0 2 !important;
  margin: 0 !important;
  align-items: baseline !important;
`;

export const EditModal: FC<{
  columns: MRT_ColumnDef<Resource>[];
  onClose: () => void;
  onSubmit: (rowIndex: number, values: Resource) => void;
  open: boolean;
  row: MRT_Row<Resource>;
}> = ({ row, open, columns, onClose, onSubmit }) => {
  const { columns: sitesColumns } = useSites();
  const { columns: agencyColumns } = useAgencies();
  const { columns: taxonomyColumns } = useTaxonomies();
  const { columns: verificationColumns } = useVerifications();

  const loggedUser = useSelector(selectUser);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [resourcesFormValues, setResourcesFormValues] = useState<Resource>(
    row.original,
  );

  const [siteFormValues, setSiteFormValues] = useState<Site>(() =>
    sitesColumns.reduce((acc, column) => {
      acc[column.accessorKey || ''] = '';
      return acc;
    }, {} as any),
  );

  const [agencyFormValues, setAgencyFormValues] = useState<Agency>(() =>
    agencyColumns.reduce((acc, column) => {
      acc[column.accessorKey || ''] = '';
      return acc;
    }, {} as any),
  );

  const [taxonomyFormValues, setTaxonomyFormValues] = useState<Taxonomy>(
    taxonomyColumns.reduce((acc, column) => {
      acc[column.accessorKey || ''] = '';
      return acc;
    }, {} as any),
  );

  const [verificationFormValues, setVerificationFormValues] =
    useState<Verification>(() =>
      verificationColumns.reduce((acc, column) => {
        acc[column.accessorKey || ''] = '';
        return acc;
      }, {} as any),
    );

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [selectedSite, setSelectedSite] = useState<string[]>([]);
  const [selectedSiteInfo, setSelectedSiteInfo] = useState<any[]>([]);

  const [selectedAgency, setSelectedAgency] = useState('');
  const [selectedTaxonomies, setSelectedTaxonomies] = useState<string[][]>([]);

  useEffect(() => {
    if (!resourcesFormValues) {
      return;
    }

    const data: any = resourcesFormValues;

    // sites []
    if (data.sites.length > 0) {
      setSelectedSite(data.sites.map((x: any) => x?._id));
    }

    // agency
    if (data.agency) {
      setSelectedAgency(data.agency.objectId);
    }

    // taxonomies [][]
    if (data.taxonomies && data.taxonomies.length) {
      const taxonomiesIdsArray = data.taxonomies.map((row: any) => {
        if (row.length > 0) {
          return row.map((value: any) => {
            return value?.objectId;
          });
        }
      });
      setSelectedTaxonomies(taxonomiesIdsArray);
    }
  }, [resourcesFormValues]);

  const handleSubmit = () => {
    let isValidFormOnSubmit = true;
    let validationErrosOnSubmit: { [cellId: string]: string } = {};
    const fields = [];

    // if (!(parseInt(resourcesFormValues.khpReferenceNumber) > 0)) {
    //   isValidFormOnSubmit = false;
    //   validationErrosOnSubmit = {
    //     ...validationErrosOnSubmit,
    //     ['khpReferenceNumber']: 'This field is required',
    //   };
    // }

    if (
      !validateRequired(resourcesFormValues.descriptionEn) &&
      !validateRequired(resourcesFormValues.descriptionFr)
    ) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['descriptionEn']: 'This field is required',
      };
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['descriptionFr']: 'This field is required',
      };
      !resourcesFormValues.descriptionEn && fields.push('descriptionEn');
      !resourcesFormValues.descriptionFr && fields.push('descriptionFr');
    }

    if (resourcesFormValues.coverage?.length === 0) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['coverages']: 'This field is required',
      };
      fields.push('coverages');
    }

    if (resourcesFormValues.documentsRequired?.length === 0) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['applicationProcess.documentsRequired']: 'This field is required',
      };
      fields.push('applicationProcess.documentsRequired');
    }

    if (
      !validateRequired(resourcesFormValues.nameEn) &&
      !validateRequired(resourcesFormValues.nameFr)
    ) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameEn']: 'This field is required',
      };
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameFr']: 'This field is required',
      };

      !resourcesFormValues.nameEn && fields.push('nameEn');
      !resourcesFormValues.nameFr && fields.push('nameFr');
    }

    if (selectedTaxonomies.length === 0) {
      fields.push('taxonomies');
      isValidFormOnSubmit = false;
    }

    if (!isValidFormOnSubmit) {
      setValidationErrors(validationErrosOnSubmit);

      toast.error(
        `Fields: ${fields.join(', ')} ${
          fields.length > 1 ? 'are' : 'is'
        } required`,
        {
          position: 'top-center',
        },
      );
      return;
    }

    const updatedResource: Resource = {
      ...resourcesFormValues,
      siteIds: selectedSite
        .slice()
        .sort(function (a, b) {
          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          } else {
            return 0;
          }
        })
        .reduce(function (a, b) {
          if (a.slice(-1)[0] !== b) {
            a.push(b);
          }
          return a;
        }, [] as string[]),
      agencyId: selectedAgency,
      taxonomyIds: selectedTaxonomies,
      lastUpdatedBy: loggedUser?.email || '',
    };

    onSubmit(row.index, updatedResource);

    toast.info(
      'Your changes have been staged. Select “confirm changes” button to finalize updates.',
      {
        position: 'top-center',
      },
    );

    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Edit Resource',
        })}

        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              '& .MuiTabs-indicator': {
                backgroundColor: palette.black.main,
                left: 0,
                width: '4px',
              },
              '& .Mui-selected': {
                color: `${palette.black.main} !important`,
              },
              height: '80vh',
              '&  #tabpanel-0': {
                marginLeft: '120px',
              },
              '&  #tabpanel-1': {
                marginLeft: '120px',
              },
              '&  #tabpanel-2': {
                marginLeft: '120px',
              },
              '&  #tabpanel-3': {
                marginLeft: '120px',
              },
              '&  #tabpanel-4': {
                marginLeft: '120px',
              },
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={handleChange}
              aria-label="Vertical tabs"
              sx={{ position: 'fixed' }}
            >
              <StyledLeftTab label="Resource" {...a11yProps(0)} />
              <StyledLeftTab label="Site" {...a11yProps(1)} />
              <StyledLeftTab label="Agency" {...a11yProps(2)} />
              <StyledLeftTab label="Taxonomy" {...a11yProps(3)} />
              <StyledLeftTab label="Verifications" {...a11yProps(4)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <ResourceForm
                columns={columns}
                values={resourcesFormValues}
                setValues={setResourcesFormValues}
                validationErrors={validationErrors}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SiteForm
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                setSelectedSiteInfo={setSelectedSiteInfo}
                selectedSiteInfo={selectedSiteInfo}
                columns={sitesColumns}
                values={siteFormValues}
                setValues={setSiteFormValues}
                agencyId={
                  resourcesFormValues?.agencyId ||
                  (resourcesFormValues?.agency as any)?._id ||
                  undefined
                }
                currentSites={
                  resourcesFormValues?.sites?.length > 0
                    ? resourcesFormValues?.sites
                    : []
                }
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <AgencyForm
                selectedAgency={selectedAgency}
                setSelectedAgency={setSelectedAgency}
                values={agencyFormValues}
                setValues={setAgencyFormValues}
                columns={agencyColumns}
                resource={resourcesFormValues}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <TaxonomyForm
                selectedTaxonomies={selectedTaxonomies}
                setSelectedTaxonomies={setSelectedTaxonomies}
                taxonomy={taxonomyFormValues}
                setTaxonomy={setTaxonomyFormValues}
                columns={taxonomyColumns}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <VerificationsForm
                objectId={
                  (resourcesFormValues?.verificationIds &&
                  resourcesFormValues?.verificationIds?.length > 0
                    ? resourcesFormValues.verificationIds[
                        resourcesFormValues.verificationIds.length - 1
                      ]
                    : '') || ''
                }
                objectIds={resourcesFormValues.verificationIds}
                columns={verificationColumns}
                values={verificationFormValues}
                setValues={setVerificationFormValues}
              />
            </TabPanel>
          </Box>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Save Changes',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-modal-tab-${index}`,
    'aria-controls': `vertical-modal-tabpanel-${index}`,
  };
};
