import { STATUS_ACTIVE, STATUS_DEACTIVATED } from 'global-constants/sites';
import { MRT_Cell, MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Site } from 'types/entities/sites';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

export const useSites = () => {
  const getCommonDateCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const date = cell.getValue<Date>();

    return date ? new Date(date).toLocaleDateString() : '';
  };

  const getStatusCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const status = cell.getValue<boolean>() || false;

    return status ? STATUS_ACTIVE : STATUS_DEACTIVATED;
  };

  const getBooleanCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const value = cell.getValue<boolean>() || false;
    return value ? 'Yes' : 'No';
  };

  const getJsonCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    try {
      // const json = JSON.parse(cell.getValue<string>() || '{}');
      const jsonAsString = JSON.stringify(cell.getValue<string>(), null, 4);
      return (
        <textarea
          disabled
          style={{
            background: 'inherit',
            height: '100px',
            border: 'none',
          }}
          value={jsonAsString || ''}
        ></textarea>
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        logger.error(e);
      }
      return null;
    }
  };

  const getListCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    try {
      const value = cell.getValue<string>();
      return (
        <textarea
          disabled
          style={{
            background: 'inherit',
            height: '50px',
            border: 'none',
          }}
          value={value || ''}
        ></textarea>
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        logger.error(e);
      }
      return null;
    }
  };

  const columns = useMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        accessorKey: '_id',
        header: intl.translate({
          id: 'Object ID',
        }),
        enableEditing: false,
        enableHiding: false,
      },
      {
        accessorKey: 'siteReferenceNumber',
        header: intl.translate({
          id: 'Site Reference Number',
        }),
      },
      {
        accessorKey: 'khpReferenceNumber',
        header: intl.translate({
          id: 'Khp Reference Number',
        }),
      },
      {
        accessorKey: 'lastVerifiedOn',
        header: intl.translate({
          id: 'Last Verified On',
        }),
      },
      {
        accessorKey: 'nameEN',
        header: intl.translate({
          id: 'Site Name EN',
        }),
      },
      {
        accessorKey: 'nameFR',
        header: intl.translate({
          id: 'Site Name FR',
        }),
      },
      {
        accessorKey: 'agencyNameEn',
        header: intl.translate({
          id: 'Agency Name En',
        }),
      },

      {
        accessorKey: 'agencyNameFr',
        header: intl.translate({
          id: 'Agency Name Fr',
        }),
      },
      {
        accessorKey: 'nameDetailsEnOfficial',
        header: intl.translate({
          id: 'Site Name Details En Official',
        }),
      },
      {
        accessorKey: 'nameDetailsEnAlternate',
        header: intl.translate({
          id: 'Site Name Details En Alternate',
        }),
      },
      {
        accessorKey: 'nameDetailsFrOfficial',
        header: intl.translate({
          id: 'Site Name Details Fr Official',
        }),
      },
      {
        accessorKey: 'nameDetailsFrAlternate',
        header: intl.translate({
          id: 'Site Name Details Fr Alternate',
        }),
      },
      {
        accessorKey: 'operationsEn',
        header: intl.translate({
          id: 'Operations En',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'operationsFr',
        header: intl.translate({
          id: 'Operations Fr',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'operations',
        header: intl.translate({
          id: 'Operations (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'transportationEn',
        header: intl.translate({
          id: 'Transportation En',
        }),
      },
      {
        accessorKey: 'transportationFr',
        header: intl.translate({
          id: 'Transportation Fr',
        }),
      },
      {
        accessorKey: 'socialFacebook',
        header: intl.translate({
          id: 'Site Facebook',
        }),
      },
      {
        accessorKey: 'socialInstagram',
        header: intl.translate({
          id: 'Site Instagram',
        }),
      },
      {
        accessorKey: 'socialTwitter',
        header: intl.translate({
          id: 'Site Twitter',
        }),
      },
      {
        accessorKey: 'isActive',
        header: intl.translate({
          id: 'Status',
        }),
        Cell: ({ cell }) => {
          return getStatusCellProps(cell);
        },
      },
      {
        accessorKey: 'isLocationPrivate',
        header: intl.translate({
          id: 'Is Location Private',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'locationAddress1',
        header: intl.translate({
          id: 'Site Primary Location Address 1',
        }),
      },
      {
        accessorKey: 'locationAddress2',
        header: intl.translate({
          id: 'Site Primary Location Address 2',
        }),
      },
      {
        accessorKey: 'locationCity',
        header: intl.translate({
          id: 'Site Primary Location City',
        }),
      },
      {
        accessorKey: 'locationCounty',
        header: intl.translate({
          id: 'Site Location County',
        }),
      },
      {
        accessorKey: 'locationProvince',
        header: intl.translate({
          id: 'Site Location Province',
        }),
      },
      {
        accessorKey: 'locationCountry',
        header: intl.translate({
          id: 'Site Location Country',
        }),
      },
      {
        accessorKey: 'locationPostalCode',
        header: intl.translate({
          id: 'Site Location Postal Code',
        }),
      },
      {
        accessorKey: 'locationDescription',
        header: intl.translate({
          id: 'Site Location Description',
        }),
      },
      {
        accessorKey: 'locationLongitude',
        header: intl.translate({
          id: 'Site Location Longitude',
        }),
      },
      {
        accessorKey: 'locationLatitude',
        header: intl.translate({
          id: 'Site Location Latitude',
        }),
      },
      {
        accessorKey: 'accessibilityEn',
        header: intl.translate({
          id: 'Wheelchair Accessibility En',
        }),
      },
      {
        accessorKey: 'accessibilityFr',
        header: intl.translate({
          id: 'Accessibility Fr',
        }),
      },
      {
        accessorKey: 'accessibility',
        header: intl.translate({
          id: 'Accessibility (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'email',
        header: intl.translate({
          id: 'Site Email',
        }),
      },
      {
        accessorKey: 'websiteEn',
        header: intl.translate({
          id: 'Site Website En',
        }),
      },
      {
        accessorKey: 'websiteFr',
        header: intl.translate({
          id: 'Site Website Fr',
        }),
      },
      {
        accessorKey: 'phoneNumbersString',
        header: intl.translate({
          id: 'Phone Numbers',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'phoneNumbers',
        header: intl.translate({
          id: 'Phone Numbers (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'contactsString',
        header: intl.translate({
          id: 'Contacts',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'contacts',
        header: intl.translate({
          id: 'Contacts (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'createdAt',
        header: intl.translate({
          id: 'Created At',
        }),
        Cell: ({ cell }) => {
          return getCommonDateCellProps(cell);
        },
      },
      {
        accessorKey: 'updatedAt',
        header: intl.translate({
          id: 'Updated At',
        }),
        Cell: ({ cell }) => {
          return getCommonDateCellProps(cell);
        },
      },
      {
        accessorKey: 'objectId',
        header: intl.translate({
          id: 'Object ID',
        }),
      },
    ],
    [],
  );

  return {
    columns,
  };
};
