/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Tab, Tabs } from '@mui/material';

import {
  approveResourceByVerifier,
  getOriginalResourceById,
  getResourceById as getStagedResourceById,
  updateResource as updateStagedResource,
  updateStatusResourceToPendingApproval,
} from 'api/stagedResources/stagedResources.api';
import { updateVerifications } from 'api/verifications/verifications.api';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { ModalBottomSection } from 'components/Shared/ModalContent/ModalBottomSection';
import { ModalTitle } from 'components/Shared/ModalTitles/ModalTitle';
import { ResourceForm } from 'components/Shared/SharedForms/ResourceForm';
import { VerificationsForm } from 'components/Shared/SharedForms/VerificationsForm';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { Text } from 'design';
import { palette } from 'design/theme/palette';
import { getChangedProperties } from 'helpers/resources.helpers';
import { useResources } from 'hooks/useResources';
import { useVerifications } from 'hooks/useVerifications';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Resource } from 'types/entities/resources';
import { Verification } from 'types/entities/verifications';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftTab = styled(Tab)`
  padding: 0 0 0 2 !important;
  margin: 0 !important;
  align-items: baseline !important;
`;

export const ResourcesForm: FC<{
  token: string;
  resource: Resource;
  onSubmit: () => void;
}> = ({ token, resource, onSubmit }) => {
  const { columns } = useResources();
  const { columns: verificationColumns } = useVerifications();

  const [resourcesFormValues, setResourcesFormValues] =
    useState<Resource>(resource);
  const [verificationFormValues, setVerificationFormValues] =
    useState<Verification>(() =>
      verificationColumns.reduce((acc, column) => {
        acc[column.accessorKey || ''] = '';
        return acc;
      }, {} as any),
    );

  const [tabValue, setTabValue] = useState(0);

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmit = async () => {
    try {
      const originalResource = await getOriginalResourceById(
        resourcesFormValues.objectId,
        token,
      );
      const changedProperties = getChangedProperties(
        originalResource,
        resourcesFormValues,
      );
      await updateStagedResource(resourcesFormValues.objectId, {
        ...resourcesFormValues,
        lastVerifiedOn: new Date(),
      });

      // if (changedProperties.length === 0) {
      //   await approveResourceByVerifier(resourcesFormValues.objectId, token);
      // } else {
      //TODO Note: Changed Properties needs to be robust for this to work well
      await updateStatusResourceToPendingApproval(
        resourcesFormValues.objectId,
        token,
      );
      // }

      // Mark external form submission date
      verificationFormValues['completionDate'] = new Date();
      // Set changed properties
      verificationFormValues['updatedPropertyNames'] = changedProperties;
      verificationFormValues['hasVerified'] = Boolean(
        verificationFormValues['hasVerified'],
      );

      await updateVerifications(
        resourcesFormValues.verifications &&
          resourcesFormValues.verifications.length > 0
          ? resourcesFormValues.verifications[
              resourcesFormValues.verifications.length - 1
            ].objectId ||
              (resourcesFormValues.verifications[
                resourcesFormValues.verifications.length - 1
              ]._id as string)
          : '',
        verificationFormValues,
      );
      onSubmit();
    } catch (err) {
      return;
    }
  };

  const getResourceDataFromDB = async (resourceId: string) => {
    if (!resourceId) {
      return;
    }
    try {
      const resource = await getStagedResourceById(resourceId, token, true);
      if (resource) {
        setResourcesFormValues(resource);
        toast.info('Properties were successfully reset', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An Error Occured', {
        position: 'top-center',
      });
    }
  };

  return (
    <Box
      sx={{
        margin: 'auto',
        maxWidth: '60vw',
        paddingTop: '2rem',
      }}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <ModalTitle>
          <Text.h1>Verify Resource</Text.h1>
        </ModalTitle>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            '& .MuiTabs-indicator': {
              backgroundColor: palette.black.main,
              left: 0,
              width: '4px',
            },
            '& .Mui-selected': {
              color: `${palette.black.main} !important`,
            },
            height: '80vh',
            '&  #tabpanel-0': {
              marginLeft: '100px',
            },
            '&  #tabpanel-1': {
              marginLeft: '100px',
            },
            '&  #tabpanel-2': {
              marginLeft: '100px',
            },
            '&  #tabpanel-3': {
              marginLeft: '100px',
            },
            overflowY: 'scroll',
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue || 0}
            onChange={handleChange}
            aria-label="Vertical tabs"
            sx={{ position: 'fixed' }}
          >
            <StyledLeftTab label="Resource" {...a11yProps(0)} />
            <StyledLeftTab label="Verification" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tabValue || 0} index={0}>
            <ResourceForm
              columns={columns}
              values={resourcesFormValues}
              setValues={setResourcesFormValues}
              validationErrors={validationErrors}
            />
          </TabPanel>
          <TabPanel value={tabValue || 0} index={1}>
            <VerificationsForm
              objectId={
                resourcesFormValues.verifications &&
                resourcesFormValues.verifications.length > 0
                  ? resourcesFormValues.verifications[
                      resourcesFormValues.verifications.length - 1
                    ].objectId ||
                    resourcesFormValues.verifications[
                      resourcesFormValues.verifications.length - 1
                    ]._id
                  : ''
              }
              original={resourcesFormValues.verifications}
              columns={verificationColumns}
              values={verificationFormValues}
              setValues={setVerificationFormValues}
            />
          </TabPanel>
        </Box>

        <Box
          sx={{
            paddingTop: '2rem',
          }}
        >
          <ModalBottomSection showUpdatedLabel={false}>
            <CancelButton
              handleClick={() => {
                getResourceDataFromDB(resource.objectId);
              }}
            >
              <strong>
                {intl.translate({
                  id: 'Reset properties',
                })}
              </strong>
            </CancelButton>

            <ConfirmButton handleClick={handleSubmit}>
              <strong>
                {intl.translate({
                  id: 'Verify',
                })}
              </strong>
            </ConfirmButton>
          </ModalBottomSection>
        </Box>
      </form>
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-modal-tab-${index}`,
    'aria-controls': `vertical-modal-tabpanel-${index}`,
  };
};
